













import { Component, Prop, Vue } from 'vue-property-decorator'
import { FillingItemInterface } from '@/store/modules/room.module'
import BaseIcon from '@/components/base/BaseIcon.vue'

// import axios from '@/api/axios'

@Component({
  name: 'FillingItem',
  components: {
    BaseIcon,
  },
})
export default class FillingItem extends Vue {
  @Prop({ required: true })
  item!: FillingItemInterface

  get imgUrl(): string {
    const url = `https://backend.delispace.ru${this.item.icon}`

    // axios
    //   .get(url, {
    //     mode: 'no-cors',
    //     headers: { 'Access-Control-Allow-Origin': '*' },
    //   })
    //   .then((response) => {
    //     console.log(response)
    //   })

    return url
  }
}
